<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Tình Trạng Sử Dụng Trạng Thái</b-card-title>
      <b-card-sub-title>
        <div class="right-button-date">
          <b-button size="sm" variant="info"><feather-icon icon="CalendarIcon" /> Hôm Nay</b-button>
          <b-button size="sm"  variant="success"><feather-icon icon="CalendarIcon" /> Tuần Này</b-button>
          <b-button size="sm" variant="warning"><feather-icon icon="CalendarIcon" /> Tháng Này</b-button>
          <b-button size="sm"><feather-icon icon="CalendarIcon" /> Tháng Trước</b-button>
        </div>
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <div style="text-align:center; margin-top: 20px; margin-bottom: 20px;">
            <div>
              <span style=" font-size: 1.2em">
                Tổng Hợp Đơn Hàng Tháng 7
              </span>
            </div>
            <span class="ft-report-status-content">
              Từ Ngày: 
            </span>
          </div>
        </b-col>
        <b-col cols="12">
          <polar-area-chart
            :height="100"
            :data="data"
          />
      </b-col>
    </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import PolarAreaChart from './components/PolarAreaChart.vue'
import {BCard, BCol, BRow, BFormDatepicker, BButton, BCardHeader, BCardBody, BCardTitle, BCardSubTite, } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  created () {
    this.data.labels = [
      'Chưa Xác Nhận (11)',
      'Xác Nhận (5)',
      'Hủy (11)',
      'Đã Thanh Toán (7)',
      'Chưa Thanh Toán (18)',
      'Khai Thác Lại (11)',
      'Xác Nhận Chốt Đơn (11)',
      'Thành Công (16)',
      'Không Nghe Điện (7)',
      'Gọi Máy Bận (3)',
      'Kế Toán Mặc Định (14)',
    ]
    this.data.datasets.push(
      {
        label: 'My First Dataset',
        data: [11, 5, 11, 7, 18, 11, 11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
          'rgb(201, 203, 207)',
          'rgb(54, 162, 235)',
          'rgb(255, 99, 32)',
          'rgb(75, 192, 92)',
          'rgb(255, 205, 186)',
          'rgb(201, 203, 17)',
          'rgb(54, 162, 105)',
          'rgb(255, 20, 1)',
          'rgb(201, 3, 17)',
          'rgb(54, 62, 105)'
        ]
      })
  },
  name: 'BarChart',
  components: { PolarAreaChart, BCard, BCol, BRow, BFormDatepicker, FeatherIcon, BButton, BCardHeader, BCardBody, BCardTitle, BCardSubTite, },
  data() {
    return {
      data: {
        labels: [
        ],
        datasets: [],
      },
    }
  },
}
</script>
<style scoped>
  .right-button-date button {
    margin-right: 5px
  }
</style>